@font-face {
font-family: 'interFont';
src: url(/_next/static/media/1e47cc70c48d9dae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'interFont';
src: url(/_next/static/media/5a9db70e0fbc3067-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'interFont';
src: url(/_next/static/media/d938fffb5d040305-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'interFont';
src: url(/_next/static/media/e11ef446ea8817ea-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'interFont Fallback';src: local("Arial");ascent-override: 98.56%;descent-override: 24.54%;line-gap-override: 0.00%;size-adjust: 98.29%
}.__className_68663a {font-family: 'interFont', 'interFont Fallback'
}.__variable_68663a {--font-inter: 'interFont', 'interFont Fallback'
}

